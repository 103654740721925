import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { navigate } from "gatsby";
import useMatchMedia from "use-match-media-hook";
import media from "styled-media-query";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button, Title } from "../../components";
import ArrowLeft from "../../images/svgs/arrow-left.svg";
import ArrowRight from "../../images/svgs/arrow-right.svg";

const Slider = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  position: relative;
`;

const Inner = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
`;

const Infos = styled.div`
  position: absolute;
  bottom: 100px;
  left: 100px;

  :after {
    content: "";
    transition: all 200ms ease;
    width: 0;
    height: 1px;
    position: absolute;
    left: -80px;
    top: 28px;
    background: white;
  }

  ${Title} {
    margin-bottom: 30px;
  }

  ${media.lessThan("medium")`
    bottom: 10px;
    left: 50px;

    ${Title} {
      font-size: 24px;
      margin-bottom: 10px;
    }
  `}
`;

const Item = styled(motion.div)`
  flex: 1 0 60vw;
  width: 60vw;
  position: relative;
  overflow: hidden;
  padding-left: 20px;

  ${media.lessThan("medium")`
    flex: 1 0 90vw;
    width: 90vw;
  `}

  h3 {
    opacity: 0;
    transition: all 600ms ease;
    transform: translateX(-10px);
  }

  button {
    opacity: 0;
    transition: all 200ms ease;
    transition-delay: 300ms;
    transform: translateX(-10px);
  }

  .gatsby-image-wrapper {
    height: 100% !important;
  }

  :hover {
    button {
      opacity: 1;
      transform: translateX(0px);
    }

    ${Infos} {
      :after {
        width: 60px;
      }
    }

    h3 {
      opacity: 1;
      transform: translateX(0px);
    }
  }
`;

const ControlButton = styled.div`
  width: 43px;
  height: 43px;
  background: #5273b0;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 3;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  cursor: pointer;
`;

const Next = styled(ControlButton)`
  right: 0;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  :hover {
    padding-right: 10px;
    width: 53px;
  }
`;
const Prev = styled(ControlButton)`
  left: 0;

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  :hover {
    padding-left: 10px;
    width: 53px;
  }
`;

const AnimatedItem = ({ children }) => <Item>{children}</Item>;

const Carousel = ({ items }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [desktop] = useMatchMedia(["(min-width: 800px)"]);

  const valToMove = useMemo(() => {
    return desktop ? 60 : 90;
  }, [desktop]);

  return (
    <Slider>
      <Prev
        onClick={() => setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 0)}
        disabled={currentSlide === 0}
      >
        <ArrowLeft />
      </Prev>
      <Inner
        animate={{ x: `-${valToMove * currentSlide}vw` }}
        transition={{ type: "spring", bounce: 0.1 }}
      >
        {items.map((item, index) => (
          <AnimatedItem>
            <GatsbyImage
              image={getImage(item.image.gatsbyImageData)}
              alt={item.image.description}
              title={item.image.title}
            />
            <Infos>
              <Title as="h3" variant="h3" color="white">
                {item.title}
              </Title>
              {item.link && (
                <Button onClick={() => navigate(item.link)} color="white">
                  Saiba mais
                </Button>
              )}
            </Infos>
          </AnimatedItem>
        ))}
      </Inner>
      <Next
        onClick={() =>
          setCurrentSlide(currentSlide <= items.length - 2 ? currentSlide + 1 : currentSlide)
        }
        disabled={currentSlide === items.length - 1}
      >
        <ArrowRight />
      </Next>
    </Slider>
  );
};

export default Carousel;

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-origgem-js": () => import("./../../../src/pages/a-origgem.js" /* webpackChunkName: "component---src-pages-a-origgem-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-hospedagem-js": () => import("./../../../src/pages/hospedagem.js" /* webpackChunkName: "component---src-pages-hospedagem-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-estrutura-js": () => import("./../../../src/templates/estrutura.js" /* webpackChunkName: "component---src-templates-estrutura-js" */),
  "component---src-templates-hospedagem-js": () => import("./../../../src/templates/hospedagem.js" /* webpackChunkName: "component---src-templates-hospedagem-js" */)
}


import styled from "styled-components";

const variants = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
};

const weight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  black: 900,
};

const styledVariants = {
  [variants.h1]: {
    size: "clamp(80.66px, 7vw, 121px)",
    weight: weight.black,
  },

  [variants.h2]: {
    size: "clamp(56px, 5vw, 81px)",
    weight: weight.black,
  },

  [variants.h3]: {
    size: "36px",
    weight: weight.black,
  },
};

const StyledTitle = styled.h1`
  font-family: "Playfair Display";
  font-size: ${({ variant }) => styledVariants[variant].size};
  font-weight: ${({ variant }) => styledVariants[variant].weight};
  color: ${({ theme, color }) => theme.colors[color] || color};
`;

StyledTitle.defaultProps = {
  as: "h1",
  variant: variants.h1,
  color: "black",
};

export default StyledTitle;

const colors = {
  darkGrey: '#262632',
  brandLight: '#5273B0',
  brandGreen: '#27494A',
  lightBlue: '#C5D0E3'
}

const fontWeights = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

const vars = {
  colors,
  fontWeights,
}

export default vars
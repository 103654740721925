import React from "react";
import { motion } from "framer-motion";
import { graphql, Link, useStaticQuery } from "gatsby";
import media from "styled-media-query";
import useMatchMedia from "use-match-media-hook";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Title } from "../../../components";

const Wrapper = styled(motion.div)`
  width: 100%;
  height: calc(100vh - 110.75px);
  background: #27494a;
  position: fixed;
  top: 110.75px;
  left: 0;
  z-index: 0;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;

  ${media.lessThan("medium")`
    top: 0;
    z-index: 99;

    h3 {
      font-size: 36px !important;
    }
  `}
`;

const Column = styled.div`
  width: 50%;

  ${media.lessThan("medium")`
    width: 100%;
  `}
`;

const Mask = styled(motion.div)`
  overflow: hidden;
  width: 100%;
  height: 100%;

  ${media.lessThan("medium")`
    transform: translateY(0) !important;
  `}
`;

const Inner = styled.div`
  width: 100%;
  height: calc(100vh - 110px);
  padding: 0 15%;
  overflow: auto;

  margin: auto;
  display: flex;
  flex-flow: row wrap;

  ${media.lessThan("medium")`
    height: 100vh !important;
    padding: 0;
    width: 90% !important;
  `}
`;

const ImageHolder = styled.div`
  transition: all 800ms ease;
  overflow: hidden;
`;

const Item = styled.div`
  margin: 100px 0;

  ${media.lessThan("medium")`
    margin: 30px 0;
  `}

  .gatsby-image-wrapper {
    transition: all 800ms ease;
  }

  :hover {
    h3 {
      transform: translateY(-10px);

      :after {
        height: 20px;
      }
    }

    .gatsby-image-wrapper {
      transform: scale(1.04);
    }
  }

  ${media.lessThan("medium")`
    h5 {
      font-size: 26px !important;
    }
  `}

  h3 {
    margin-top: 30px;
    margin-left: 20px;
    position: relative;
    transition: all 400ms ease;

    &:after {
      content: "";
      width: 2px;
      height: 30px;
      background: white;
      position: absolute;
      bottom: 100%;
      transition: all 400ms ease;
      left: 0;
    }
  }
`;

const ItemLink = styled(Link)`
  text-decoration: none;
`;

const Structure = ({ isOpen }) => {
  const [desktop] = useMatchMedia(["(min-width: 800px)"]);

  const { allContentfulEstrutura } = useStaticQuery(graphql`
    query {
      allContentfulEstrutura(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            id
            titulo
            slug
            capa {
              gatsbyImageData(layout: CONSTRAINED, width: 665, height: 368)
              title
              description
            }
          }
        }
      }
    }
  `);

  return (
    <Wrapper
      initial={{ height: 0 }}
      animate={{
        height: isOpen ? (desktop ? "calc(100vh - 110px)" : "100vh") : 0,
        transition: { delay: 0.3, bounce: 0, type: "spring" },
      }}
      exit={{ height: 0 }}
    >
      <Mask
        animate={{
          scale: isOpen ? 1 : 1.1,
          y: isOpen ? 0 : -100,
          transition: { delay: 0.3, bounce: 0, type: "spring" },
        }}
      >
        <Inner>
          <Column>
            <Title color="white" as="h3" variant="h2" style={{ position: "sticky", top: 200 }}>
              Nossa <br />
              Estrutura
            </Title>
          </Column>
          <Column>
            {allContentfulEstrutura.edges.map(({ node }) => (
              <Item>
                <ItemLink to={`/nossa-estrutura/${node.slug}`}>
                  <ImageHolder>
                    <GatsbyImage
                      image={getImage(node.capa.gatsbyImageData)}
                      alt={node.capa.description}
                      title={node.capa.title}
                    />
                  </ImageHolder>
                  <Title as="h5" variant="h3" color="white">
                    {node.titulo}
                  </Title>
                </ItemLink>
              </Item>
            ))}
          </Column>
        </Inner>
      </Mask>
    </Wrapper>
  );
};

export default Structure;

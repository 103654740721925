import React from "react";
import styled, { keyframes } from "styled-components";
import { Text } from "../../components";

const line = keyframes`
  0% {
    width: 90px;
  }

  50% {
    width: 80px;
  }

  100% {
    width: 90px;
  }
`;

const textAnimation = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
`;

const Container = styled.div`
  position: absolute;
  bottom: -20px;
  right: 60px;
  padding-right: 100px;
  z-index: 3;
  transform-origin: top right;
  transform: rotate(90deg);

  :after {
    content: "";
    width: 90px;
    height: 1px;
    background: ${({ color }) => color};
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    animation: ${line} 5s ease-in-out infinite;
  }

  ${Text} {
    color: ${({ color }) => color};
    font-size: 12px;
    font-family: "Raleway";
    text-transform: uppercase;
    animation: ${textAnimation} 5s ease-in-out infinite;
  }
`;

const ScrollIndicator = ({ color = "white" }) => {
  return (
    <Container color={color}>
      <Text color={color}>Scroll</Text>
    </Container>
  );
};

export default ScrollIndicator;

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  *, *:after, *:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  html, body {
    font-family: 'Raleway';
    background: #eee;
  }

  p {
    font-size: 18px;
    line-height: 1.5em;
    font-family: 'Raleway';
    color: black;
  }

  a {
    text-decoration: none;
  }
`;

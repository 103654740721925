import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import theme from "../../theme";

export const Bullets = styled(motion.div)`
  display: flex;
  flex-flow: row wrap;
`;

export const StepBullets = ({ children }) => <Bullets>{children}</Bullets>;

export const Bullet = styled(motion.div)`
  width: 30px;
  height: 4px;
  background: ${({ theme }) => theme.colors.lightBlue};
  border-radius: 20px;
  transition: all 300ms ease;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.colors.brandLight};
    transform: scaleX(1.2);
  }

  :not(:last-of-type) {
    margin-right: 36px;
  }
`;

export const StepBullet = (props) => <Bullet {...props} />;

const BulletsComponent = ({ active = 0, total = 0, onChangeCurrent, ...props }) => {
  return (
    <StepBullets {...props}>
      {[...Array(total)]
        .map((v, i) => i++)
        .map((item, index) => {
          return (
            <StepBullet
              key={`Bullet_${index}`}
              animate={{
                background: active === index ? theme.colors.brandLight : theme.colors.lightBlue,
              }}
              onClick={() => onChangeCurrent(index)}
            />
          );
        })}
    </StepBullets>
  );
};

export default BulletsComponent
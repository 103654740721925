import styled from 'styled-components'

const Text = styled.p`
  font-size: 18px;
  line-height: 1.5em;
  font-family: 'Raleway';
  color: ${({theme, color}) => theme.colors[color] || color};
`

Text.defaultProps = {
  color: 'black'
}

export default Text

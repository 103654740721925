import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import media from "styled-media-query";
import { motion } from "framer-motion";
import useMatchMedia from "use-match-media-hook";
import Logo from "../../images/svgs/origgem.svg";
import theme from "../../theme";
import Structure from "./Structure";

const Wrapper = styled(motion.header)`
  width: 100%;
  background: white;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 26px clamp(10px, 3vw, 140px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;

  ${media.lessThan("medium")`
    height: 100vh;
    top: 0;
    left: 0;
    padding: 0;
    justify-content: center;
    flex-direction: column;
  `}
`;

const Nav = styled.nav`
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  ${media.lessThan("medium")`
    flex-direction: column;
    margin: 0;
    justify-content: center;
  `}
`;

const Menu = styled.ul`
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  margin-right: clamp(50px, 4vw, 100px);

  ${media.lessThan("medium")`
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    margin-top: calc(100vh - 520px);
    margin-bottom: calc(100vh - 520px);
  `}
`;

const Item = styled.li`
  transition: all 300ms ease;
  position: relative;

  :not(:last-of-type) {
    margin-right: clamp(1px, 7vw, 100px);
  }

  :after {
    content: "";
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.colors.brandLight};
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    right: 0;
    margin: auto;
    transform: scale(0.4);
    transition: all 600ms ease;
    opacity: 0;
  }

  :hover {
    transform: scale(1.2);

    :after {
      transform: scale(1);
      opacity: 1;
    }
  }

  a {
    font-size: 12px;
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.darkGrey};
    text-decoration: none;
  }

  ${media.lessThan("medium")`
    margin-right: 0 !important;
    text-align: center;
    display: flex;
    justify-content: center;
    a {
      padding: 20px;
    }
  `}
`;

const Medias = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const Media = styled.li`
  :not(:last-of-type) {
    margin-right: clamp(5px, 2vw, 100px);
  }

  ${media.lessThan("medium")`
    margin: 0 40px;
  `}
`;
const Hamburger = styled.div`
  width: 40px;
  height: 40px;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  align-content: center;

  div {
    width: 80%;
    height: 3px;
    background: #27494a;
    transition: all 300ms ease;

    :nth-child(2) {
      margin: 5px 0;
    }
  }

  ${({ isOpenSubmenu }) =>
    isOpenSubmenu &&
    `
    div {
      background: white !important;
    }
  `}

  ${({ isOpen }) =>
    isOpen &&
    `
    div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto !important;
      transform-origin: 50% 50%;

      :nth-child(1) {
        opacity: 0;
      }

      :nth-child(2) {
        transform: rotate(45deg);
      }

      :nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  `}
`;

const menu = [
  {
    id: 1,
    label: "Home",
    to: "/",
  },
  {
    id: 2,
    label: "A Origgem",
    to: "/a-origgem",
  },
  {
    id: 3,
    label: "Nossa Estrutura",
    submenu: <div>oioi</div>,
    to: "#",
  },
  {
    id: 4,
    label: "Hospedagem",
    to: "/hospedagem",
  },
  {
    id: 5,
    label: "Contato",
    to: "/contato",
  },
];

const Header = () => {
  const [openStructure, setOpenStructure] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [desktop] = useMatchMedia(["(min-width: 800px)"]);

  useEffect(() => {
    document.body.style.overflowY = openStructure ? "hidden" : "unset";
  }, [openStructure]);

  return (
    <>
      {!desktop && (
        <Hamburger
          isOpenSubmenu={openStructure}
          isOpen={isOpenMenu}
          onClick={() => (openStructure ? setOpenStructure(false) : setIsOpenMenu(!isOpenMenu))}
        >
          <div />
          <div />
          <div />
        </Hamburger>
      )}
      <Wrapper
        animate={{
          x: desktop ? 0 : isOpenMenu ? 0 : "100%",
          transition: { type: "spring", bounce: 0.1 },
        }}
      >
        <Link to="/">
          <Logo />
        </Link>

        <Nav>
          <Menu>
            {menu.map((item) => (
              <Item key={`MenuItem_${item.id}`}>
                <Link to={item.to} onClick={() => item.submenu && setOpenStructure(!openStructure)}>
                  {item.label}
                </Link>
              </Item>
            ))}

            <Structure isOpen={openStructure} />
          </Menu>

          <Medias>
            <Media>
              <a href="https://www.facebook.com/origgemcaxias" alt="Facebook" title="Facebook" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  icon={["fab", "facebook-f"]}
                  color={theme.colors.darkGrey}
                  size="1x"
                />
              </a>
            </Media>
            <Media>
              <a href="https://www.instagram.com/origgem_/" alt="Instagram" title="Instagram" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  icon={["fab", "instagram"]}
                  color={theme.colors.darkGrey}
                  size="1x"
                />
              </a>
            </Media>
          </Medias>
        </Nav>
      </Wrapper>
    </>
  );
};

export default Header;

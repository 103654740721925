import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import media from "styled-media-query";

import Header from "../Header";
import Footer from "../Footer";

const Main = styled.main`
  padding-top: 110.75px;
  overflow: hidden;
  background: ${({ backgroundColor }) => backgroundColor && backgroundColor};

  ${media.lessThan("medium")`
    padding-top: 0;
  `}
`;

const Page = ({ children, title = "", description = "", meta = "", backgroundColor }) => {
  return (
    <>
      <Helmet>
        <title>Origgem {title && ` - ${title}`}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://fervent-jang-a90fde.netlify.app"></link>
      </Helmet>
      <Main backgroundColor={backgroundColor}>
        <Header />
        {children}
        <Footer />
      </Main>
    </>
  );
};

export default Page;

import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import media from "styled-media-query";

const StyledButton = styled(motion.button)`
  border: 1px solid ${({ theme, color }) => color || theme.colors.brandLight};
  background: transparent;
  padding: 21px 54px;
  color: ${({ theme, color }) => color || theme.colors.brandLight};
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  transition: all 300ms ease-out;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  outline: none;

  ${media.lessThan("medium")`
    padding: 10px 20px;
  `}

  :after {
    content: "";
    width: 100%;
    height: 0px;
    background: ${({ theme, color }) => color || theme.colors.brandLight};
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 200ms ease-out;
  }

  :hover {
    cursor: pointer;
    border-color: transparent;
    :after {
      height: 3px;
    }
  }
`;

const Button = ({ type = "button", children, ...props }) => {
  return (
    <StyledButton type={type} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
